import React, { useState, useEffect } from 'react'
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import qs from 'qs';

const useStyles = makeStyles({
  container: {
    width: '90%',
    maxWidth: '600px',
    margin: '50px auto'
  },
  text: {
    color: '#666666',
    fontSize: '24px'
  },
  logo: {
    width: '80px',
    borderRadius: 8
  }
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#00502F'),
    fontSize: '22px',
    backgroundColor: '#00502F',
    '&:hover': {
      backgroundColor: '#15716d',
    },
  },
}))(Button);

const PasswordReset = () => {
  const classes = useStyles();
  let history = useHistory();
  const [disableInput, setDisableInput] = useState(false);
  const [typeMsg, setTypeMsg] = useState('success');
  const [resCaptcha, setResCaptcha] = useState(null);
  const token = localStorage.getItem("token");

  const fetchCaptcha = () => 
    axios({
      headers: {
        'X-ApiKey': process.env.REACT_APP_KEY,
      },
      url: `${process.env.REACT_APP_API}/captcha`, 
    }).then(response => {
      if (response.status === 200) {
        const data = response.data
        setResCaptcha(data)
      }
    }).catch(err => console.log(err.message))


  useEffect(() => {
    fetchCaptcha()
    if(token !== null){
      history.push({
        pathname: '/home'
      })
    }
  }, [token, history])

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    captcha: yup
      .string('Enter your captcha')
      .required('Captcha is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      captcha: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting, isSubmitting, setFieldValue }) => {
      if (isSubmitting){
        return;
      }
      axios({
        method: "POST",
        headers: {
          'X-ApiKey': process.env.REACT_APP_KEY,
          'Content-Type':'application/x-www-form-urlencoded;charset=utf-8'
        },
        url: `${process.env.REACT_APP_API}/user/sendemail`, 
        data: qs.stringify({
          email: values.email,
          captcha_input: values.captcha,
          captcha_token: resCaptcha.captcha_token,
        }),
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            setTypeMsg('success')
            setStatus(`We sent an email to ${values.email} with a link to get back into your account.`)
            setDisableInput(true)
            setSubmitting(false)
            return
          }
        }
        setTypeMsg('error')
        setStatus(response.data.message)
          // disabled button loading
          setSubmitting(false)
      })
      .catch(error => {
        localStorage.clear()
        setTypeMsg('error')
        if (error.response !== undefined) {
          setResCaptcha(null)
          setStatus(error.response.data.message)
          fetchCaptcha()
        }else{
          setStatus(error.message)
        }
        // disabled button loading
        setSubmitting(false)
        // reset captcha
        setFieldValue('captcha', '')
      })
    },
  });

  return (
    <>
      <div className={classes.container}>
        <div className="text-center mb-3">
          <img className={classes.logo} src={process.env.PUBLIC_URL + "/media/img/logo-v1.png"} alt="Logo OneIP" />
        </div>

        <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(e); }} >
          {formik.status ? (          
            <Alert className="mb-3" severity={typeMsg}>{formik.status}</Alert>
          ) : ""}
          <p hidden={disableInput} className="text-center mb-3">
            <b className={classes.text}>Reset your password</b>
          </p>
          <TextField
            id="email"
            {...formik.getFieldProps("email")}
            name="email"
            className="mb-3"
            type="text"
            placeholder="Email"
            label="" 
            variant="outlined" 
            fullWidth
            hidden={disableInput}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputProps={{
              startAdornment: 
              <InputAdornment position="start">
                <MailOutlineIcon color="disabled" />
              </InputAdornment>,
            }} />
          <div hidden={disableInput} className='text-center mb-3'>
            {
              resCaptcha ? 
              <>
                <img src={resCaptcha.captcha_image} alt="captcha" />
                <div style={{ color: '#00502F', fontSize: '16px' }}  className='text-center mt-2'>
                  <span style={{cursor: 'pointer'}} onClick={fetchCaptcha}>Generate New Image</span>
                </div>
              </> : <CircularProgress />
            }
          </div>
          <TextField
            hidden={disableInput}
            id="captcha"
            {...formik.getFieldProps("captcha")}
            name="captcha"
            className="mb-3"
            type="text"
            placeholder="Input Captcha"
            label="" 
            variant="outlined" 
            fullWidth
            value={formik.values.captcha}
            onChange={formik.handleChange}
            error={formik.touched.captcha && Boolean(formik.errors.captcha)}
            helperText={formik.touched.captcha && formik.errors.captcha}
            />
          <ColorButton hidden={disableInput} disabled={formik.isSubmitting} type="submit" fullWidth variant="contained" color="primary">
            Send Login Link
          </ColorButton>
          {formik.isSubmitting && <LinearProgress />}
          <div 
            style={{ color: '#00502F', fontSize: '16px' }} 
            className="text-center mt-3">
              <span style={{cursor: 'pointer'}} onClick={() => history.push({ pathname: '/' }) }>Back To Login</span>
          </div>
        </form>
      </div>
    </>
  )
}

export default PasswordReset